@font-face{
  font-family:"NettoWebBold";
  src:url("../fonts/8934d946-1a0c-401d-b823-e4f87774b7b0.eot?#iefix");
  src:url("../fonts/8934d946-1a0c-401d-b823-e4f87774b7b0.eot?#iefix") format("eot"),
  url("../fonts/f4cfd432-2928-44cb-8afd-8348c18eedbd.woff2") format("woff2"),
  url("../fonts/2acd961d-3e8b-4ef1-b369-a1850f8f43c9.woff") format("woff"),
  url("../fonts/898d74fd-b23c-4623-b572-3d1c4a5aedce.ttf") format("truetype");
}
@font-face{
  font-family:"NettoWebRegular";
  src:url("../fonts/59e6ab9f-6189-4313-99f7-60d3e51d3030.eot?#iefix");
  src:url("../fonts/59e6ab9f-6189-4313-99f7-60d3e51d3030.eot?#iefix") format("eot"),
  url("../fonts/1627436b-e6ea-4f62-a9f4-d09837aa7f56.woff2") format("woff2"),
  url("../fonts/ecf327d8-8747-4de7-a77d-fc2beaa85e98.woff") format("woff"),
  url("../fonts/9120308d-5784-47b5-8975-b7596eebf822.ttf") format("truetype");
}




/*font size for mobile header hack*/
@media only screen and (max-width: 800px) {
  .category-menu4{
    h1{
      font-size: 36px;
    }
    .post-header{
      font-size: 36px;
    }
  }

  //font-size: 34px;
}



.partner,.steuerbefreiung,.impressum{
  #panel{
    background-color: $footer-menu-background-color;
  }
}
/*page mein paddings*/
.page {
  #panel{
    padding: 50px;
  }
}
/*header hack*/
.post-header{
  display: inline-block;
  float: left;
  font-size: 52px;
  padding: 0;
  margin: 0;
  color: #347fac;
  @media only screen and(max-width: 1280px) {
    font-size: 41px ;
  }
}

/*image undertext*/

.image-undertext{
  font-size: 13px;
  line-height: 1.2;
}
/*slider pagination */
.flex-control-paging{
  display: none;
}
/*hack*/
@mixin triangle($color) {
  content: '';
  display: block;
  position: absolute;
  left: -22px;
  bottom: 100%;
  width: 0;
  height: 0;
  border-bottom: 10px solid transparent;
  border-top: 26px solid $color;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-radius: 5px;
  top: -68px;
  z-index: 99999;
  @media only screen and (max-width: 768px) {
    border: none !important;
  }
}
.padding-top-0{
  padding-top: 0;
}
.category-menu5{
  article{
    padding: 0 !important;
  }
  .paddings{

    padding: 50px;
    &:nth-child(even){
      padding-top: 0!important;
    }
  }
}
.padding-bottom-0{
  padding-bottom: 0px !important;
}
.wpb_gallery_slides{
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow:  none !important;
}
#post-175 > div > div.vc_row.wpb_row.vc_row-fluid.padding-bottom-0.vc_custom_1460975267601.vc_row-has-fill > div > div > div > div > div > div{
  margin-bottom: 25px;
}
.category-menu4{

  .wpb_gallery{
    margin-bottom: 0px !important;
  }
  #post-175 > div > div:nth-child(1){
    padding-bottom: 0px;
  }
  #post-175 > div > div.vc_row.wpb_row.vc_row-fluid.paddings.vc_custom_1460557613193.vc_row-has-fill > div.wpb_column.vc_column_container.vc_col-sm-10 > div{
    padding-top: 15px;
  }
  /*
  .wpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid>li{
    margin-bottom: 0px;
  }
  */
  article{
    padding: 0 !important;
  }
  .paddings{

    padding: 50px;
    &:nth-child(even){
      padding-top: 0!important;
    }
  }
}

.border-left-black{

  border-left:5px solid #f1eae3;
  &:after{
    @include triangle(#f1eae3);
  }
  @media only screen and (max-width: 768px) {
    border-left: none !important;
  }
}

.border-left-white{

  border-left:5px solid white;
  &:after{
    @include triangle(white);
  }
  @media only screen and (max-width: 768px) {
    border-left: none !important;
  }
}
/*bullshit*/
.paddings-bilder{
  margin-top: 0px;
  padding: 50px;
  &:nth-child(even){
    padding-top: 0!important;
  }
}
/*text hack */

.wpb_wrapper{
  .data-text{
    display: inline-block;
    float: left;
  }
  .icons-box{
    display: inline-block;
    float: right;
  }
}


.paddings-bilder-0{
  .vc_column-inner{
    padding: 5px;
  }

}
/*end hack*/
.vc_separator{
  margin-bottom: 10px;
}
.border-bottom-5-gray{
  border-color: #f1eae3;
  border-width: 5px;
}
.border-bottom-5-white{
  border-color: white;
  border-width: 5px;
}
.margin-text-top-10{
  margin-top: 10px;
}
.paragraph-15{
  font-size: 1.1em;
}
.bilder{
  margin-top: 50px;
}
.wpb_text_column{
  margin-bottom: 10px;
}
#tinymce{
  width: auto !important;
  height: auto !important;
}

html,body{
  font-family: "NettoWebRegular",sans-serif;
  height: 100%;
  width: 100%;
}
h1,h2,h3,h4,h5{
  margin-top: 10px;
  margin-bottom: 10px;
}
h2{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px
}
.entry-title{
  font-weight: bold;
}
h1{
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "NettoWebBold", sans-serif;
  font-size: 52px;
  color:$h1-color;
  @media only screen and(max-width: 1280px) {
    font-size: 41px ;
  }
}
.container{
  /*
  @media only screen and (max-width: 1600px) {
    width: 950px;
  }
  */
  @media only screen and (max-width: 1280px) {
    width: 850px;
  }
  @media only screen and (max-width: 1024px) {
    width: auto;
  }

}
.text-margin-top-15{
  margin-top: 15px;
}
.text-margin-top-35{
  margin-top: 35px;
}
.text-bottom-margin-image{
  margin-bottom: 5px;
}
.column-padding-5px{
  padding-left: 5px;
  padding-right: 5px;
}
.margin-bottom-10px {
  margin-bottom:10px;
}

.less-margin{
  margin-bottom: 10px;
}
#panel{
  padding:0px;
}
article{
  padding: 50px;
  &:nth-child(odd){
    background-color: #f1eae3;
  }
  &:nth-child(even){
    background-color: transparent;
  }
}
.container{

  box-shadow: 6px 0 4px -4px rgba(0,0,0,0.4) , -6px 0 4px -4px rgba(0,0,0,0.4);
  -moz-box-shadow: 6px 0 4px -4px rgba(0,0,0,0.4), -6px 0 4px -4px rgba(0,0,0,0.4);
  -webkit-box-shadow: 6px 0 4px -4px rgba(0,0,0,0.4), -6px 0 4px -4px rgba(0,0,0,0.4);
}

/*
.slideout-menu {
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  width: 256px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
}

.slideout-panel {
  position: relative;
  z-index: 1;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}
*/
.logotips{
  position: relative;
  height: 140px;

}
/*submit form*/
.footer-contact{

  h1{
    padding-left: 15px;
    color:white;
    border-left: 5px solid white;
  }
  .button-send {
    color: white;
    background-color: black;
    border: none;
    border-radius: 0;
    &:hover, &:active, &:focus,&:visited{
      color: white;
      background-color: black;
    }

  }
  #wpcf7-f83-o1 > form > div > div > span > span{
    color: white !important;
  }
  .form-control{
    border-radius: 0;
  }
  .wpcf7-response-output {
    display: none !important;
  }
}

/*GLOBAL SLIDER*/
.full-size-galery{

  .wpb_gallery_slides{
    border:none !important;
    .nivoSlider{
      box-shadow: none !important;
    }
  }
}
/*list style from article header*/
.entry-list{
  padding-left: 15px;
  font-weight: bold;
  font-size: 16px;


}
p{
  font-size: 15px;
}