
@mixin header-color($color) {
  .menu-item{
    .header{
      font-size: 16px;
      width: 93px;
      height: 55px;
      padding-bottom: 5px;
      margin-bottom: 10px;

      a{
        text-transform: uppercase;
        color: $color;
        p{
          line-height: 18px;

        }
      }
    }
    .sub-menu{
      font-size: 14px;
      padding-top: 5px;
      border-top: 4px solid white;
      text-transform: uppercase;
      a{
        p{
          line-height: 15px;
        }
      }
    }
  }
}

#footer{

  .contact-form{

    .container {
      padding-bottom: 20px;
      .footer-contact{
        padding-top: 20px;
        h1{
          margin-bottom: 10px;
        }
        .wpcf7{
          padding: 15px;
        }
      }
      .details{
        margin-top: 160px;
        p{
          margin: 0;
          color: white;
          font-size: 18px;
        }
        a{
          color: white;
          text-decoration: none;
        }
      }
    }

    background-color: $footer-background-color;
  }
  .pages{
    ul{
      padding: 15px;
      li{
        padding-left: 5px;
        a{
          text-transform: uppercase;
          color:black;
          text-decoration: none;
        }
        display:inline-block;
        list-style: none;
        &:not(:last-child){

          padding-right: 10px;
          border-right: 1px solid black;
        }
      }
    }
  }
  .footer-menu{
    -webkit-box-shadow:  -1px 28px 29px -21px rgba(0,0,0,.6);
    -moz-box-shadow:  -1px 28px 29px -21px rgba(0,0,0,.6);
    box-shadow:  -1px 28px 29px -21px rgba(0,0,0,.6);
    .container{
      box-shadow: none;
    }
    margin-top: 20px;
    background-color:$footer-menu-background-color;


    .primary-menu {
      display: inline-block;
      float: left;
      padding: 15px;

      &:nth-child(1){
        @include header-color($menu-1-color);
      }
      &:nth-child(2) {
        @include header-color($menu-2-color);
      }

      &:nth-child(3){
        @include header-color($menu-3-color);
      }
      &:nth-child(4){

        @include header-color($menu-4-color);
      }
      &:nth-child(5){
        @include header-color($menu-5-color);

      }

      .sub-menu{

        a{
          word-break: break-word;
          text-decoration: none;

          color:white;
          &:hover{
            text-decoration: none;
          }
        }
        ol{
          color:white;
          padding-left: 0px;
          li{
            word-break: break-all;
            list-style: none;

          }
        }

      }

    }

  }
  .company-signiture{
    .container{
      padding-top: 20px;
    }
  }

}