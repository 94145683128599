// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;

$h1-color :#bc9a80;

//backgrounds

$body-background:#ffffff;
$container-background-color :#f1eae3;
$footer-background-color :#e84e10;
$footer-menu-background-color: #d3bbaa;
//menu colors
$menu-1-color: #e84e10;
$menu-2-color: #83a17e;
$menu-3-color: #f39200;
$menu-4-color: #347fac;
$menu-5-color: #bc9a80;

