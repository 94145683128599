@mixin triangle($color) {
  content: '';
  display: block;
  position: absolute;
  left: 20px;
  bottom: 100%;
  width: 0;
  height: 0;
  border-bottom: 10px solid transparent;
  border-top: 26px solid $color;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-radius: 5px;

}
@mixin active(){
  position: relative;
  -webkit-box-shadow: 0px 8px 18px 1px rgba(0,0,0,.72);
  -moz-box-shadow: 0px 8px 18px 1px rgba(0,0,0,.72);
  box-shadow: 0px 8px 18px 1px rgba(0,0,0,.72);
}
#header{
  position: relative;
  margin-top: 140px;
  height: 164px;
  .logotip-bauer{

      position: absolute;
      width: 440px;
    top: -120px;
      right: -145px;
    @media only screen and (max-width: 1024px) {
      width: 300px;
      top: 19px;
      right: -15px;
    }
      @media only screen and (max-width: 500px) {
        width: 300px;
      }

  }
  @media only screen and (max-width: 1024px) {
    margin-top: 0;
    height: 90px;
  }
}
.menu{

  background-color: #f1eae3;
  height: 171px;
  position: relative;
  .img-jahre{
    position: absolute;
    right: -100px;
    top: -25px;
    img{
      -webkit-box-shadow: 7px 10px 8px -4px rgba(0,0,0,0.4);
      -moz-box-shadow: 7px 10px 8px -4px rgba(0,0,0,0.4);
      box-shadow: 7px 10px 8px -4px rgba(0,0,0,0.4);
      width: 160px;
      height: 160px;
      border-radius: 5px;
    }
  }

  #menu{
    //height: 130px;
    left: -160px;
    position: absolute;
    z-index: 99999;

    .primary-menu{
      width: 160px;
      display: inline-block;
      float: left;
      padding: 15px;
      height: 160px;


      &:nth-child(1){
        background-color: $menu-1-color;
        &.active{
          @include active();

          &:after {
            @include triangle($menu-1-color);
          }
        }
      }
      &:nth-child(2){
        background-color: $menu-2-color;
        &.active{
          @include active();

          &:after {
            @include triangle($menu-2-color);
          }
        }


      }
      &:nth-child(3){
        background-color: $menu-3-color;
        &.active{
          @include active();

          &:after {
            @include triangle($menu-3-color);
          }
        }
      }
      &:nth-child(4){
        background-color: $menu-4-color;
        &.active{
          @include active();

          &:after {
            @include triangle($menu-4-color);
          }
        }

      }
      &:nth-child(5){
        background-color: $menu-5-color;
        &.active{
          @include active();

          &:after {
            @include triangle($menu-5-color);
          }
        }
      }
      .menu-item{
        .header{
          font-size: 16px;
          width: 93px;
          border-bottom: 4px solid white;
          font-weight: bold;
          margin-bottom: 80px;
          a{
            color:white;
            text-decoration: none;
            text-transform: uppercase;
            p{
              line-height: 18px;
              margin-bottom: 5px;
            }
            &:hover{
              text-decoration: none;
            }
          }
        }
        .sub-menu{
          font-size: 14px;
          display: none;
          a{
            //font-weight: bold;
            word-break: break-word;
            text-decoration: none;
            color:white;
            p{
              padding-left: 5px;
              padding-top: 2px;
              display: -moz-inline-box;
              display: inline-block;
              line-height: 16px;
              margin-bottom: 2px;
            }
            &:hover{
              text-decoration: none;
            }
          }
          ol{
            color:white;
            padding-left: 0px;
            li{
              span{
                display: inline-block;
                float: left;
              }
              word-break: break-word;
              list-style:none;
              text-transform: uppercase;
              //display: list-item;
              //text-align: -webkit-match-parent;
              //list-style: none;
              border-bottom: 4px solid white;
              margin-bottom: 0px;
            }
          }
        }

      }
      &:hover{

        z-index: 9999999;
        position: relative;
        //height: 200px;
        -webkit-box-shadow: 0px 8px 18px 1px rgba(0,0,0,.72);
        -moz-box-shadow: 0px 8px 18px 1px rgba(0,0,0,.72);
        box-shadow: 0px 8px 18px 1px rgba(0,0,0,.72);
        height: auto!important;
      }
      &:hover .menu-item .sub-menu {
        transition: all .7s ease .7s;
        display: block;

      }
    }
  }
}
